import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/Button/Button'

interface ILoyaltyRegistrationIntroProperties {
    handleOnboarding: () => void
}
const LoyaltyRegistrationIntro: FC<ILoyaltyRegistrationIntroProperties> = ({ handleOnboarding }) => {
    const { t } = useTranslation()

    const handleClick = useCallback(() => {
        handleOnboarding?.()
    }, [handleOnboarding])

    return (
        <div className='loyaltyregistration-intro'>
            <h1 className='u-text-center'>{t('loyalty.modal.registration.intro.title')}</h1>
            <p>{t('loyalty.modal.registration.intro.body')}</p>
            <div className='loyaltyregistration-cta'>
                <Button
                    label='Get started'
                    variant='dark'
                    onClick={handleClick}
                />
            </div>
        </div>
    )
}

export default LoyaltyRegistrationIntro
