import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ExpandableText } from 'src/components/01_atoms/ExpandableText/ExpandableText'
import Modal from 'src/components/01_atoms/Modal/Modal'
import ModalControls from 'src/components/01_atoms/ModalControls/ModalControls'
import ProductAllergens from 'src/components/01_atoms/ProductAllergens/ProductAllergens'
import ProductModalImage from 'src/components/01_atoms/ProductModalImage/ProductModalImage'
import ProductTags from 'src/components/01_atoms/ProductTags/ProductTags'
import Button from 'src/components/Button/Button'
import useValidateAdditions, { ISelectedAdditions } from 'src/hooks/useValidateAdditions'
import { appendAdditionsToProduct, closeModal } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'
import IProduct from 'src/structures/Interfaces/IProduct'

import Additions from '../../Additions/Additions'

import './upsellmodal.scss'

const UpsellModal: FC<IModalDataProperties> = ({ data }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const product: IProduct = data?.product ?? null
    const isOrderable = data?.isOrderable ?? true
    const hasAdditions = data?.product?.additions?.length > 0

    const { validateAdditions, totalAdditionsPrice, selectedAdditions, isValid } = useValidateAdditions(
        data.product?.additions
    )

    const onUpdateAdditions = useCallback(
        (values: ISelectedAdditions) => {
            validateAdditions(values)
        },
        [validateAdditions]
    )

    const handleConfirm = useCallback(() => {
        dispatch(
            appendAdditionsToProduct({
                ...product,
                event_id: data.event_id,
                additions: selectedAdditions,
                has_additions: Object.keys(selectedAdditions).length > 0,
                totalAdditionsPrice,
            })
        )

        data.onConfirm?.()
        dispatch(closeModal())
    }, [data, dispatch, selectedAdditions, totalAdditionsPrice])

    if (isNotNullOrUndefined(product)) {
        return (
            <Modal
                containerClassName='upsellmodal-container'
                contentClassName='upsellmodal-content'>
                <div className='inner'>
                    <ProductModalImage
                        image={product.images?.medium ?? undefined}
                        imageSize={isNotNullOrUndefined(product.image_size) ? product.image_size : 'cover'}
                    />

                    <div className='upsellmodal-title'>
                        <h2 className='upsellmodal-title-product-name'>{product.name}</h2>
                    </div>

                    {isNotNullOrUndefined(product.description) && product.description.length > 0 ? (
                        <div className='upsellmodal-description'>
                            <ExpandableText
                                id={String(product.id)}
                                text={product.description}
                            />
                        </div>
                    ) : null}

                    <ProductAllergens allergens={product.allergens} />
                    <ProductTags tags={product.tags} />

                    {isNotNullOrUndefined(product.additions) && hasAdditions ? (
                        <Additions
                            isOrderable={isOrderable}
                            additions={product.additions ?? []}
                            onAdditionsChange={onUpdateAdditions}
                        />
                    ) : null}

                    <ModalControls>
                        <Button
                            className='add-button'
                            label={t('button.add_to_basket.cta')}
                            onClick={handleConfirm}
                            disabled={!isValid}
                            variant='dark'
                        />
                    </ModalControls>
                </div>
            </Modal>
        )
    }

    return null
}

export default UpsellModal
