import { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineMail } from 'react-icons/md'
import classNames from 'classnames'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Button from 'src/components/Button/Button'
import { UserContext } from 'src/context/UserAndTokenContextProvider'
import * as Yup from 'yup'

interface ILoyaltyRegistrationFormProperties {
    onSuccessfulSignup: (email: string) => void
}

interface IFormValues {
    email: string
}

const LoyaltyRegistrationForm: FC<ILoyaltyRegistrationFormProperties> = ({ onSuccessfulSignup }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { user } = useContext(UserContext) as any

    const validationSchema = Yup.object({
        email: Yup.string().email().required(t('validation.email.required')),
    })

    const initialValues: IFormValues = {
        email: user?.email ?? '',
    }

    const handleSubmit = useCallback(
        (values: IFormValues) => {
            onSuccessfulSignup?.(values.email)
        },
        [onSuccessfulSignup]
    )

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ isSubmitting, errors }) => (
                <Form>
                    <div className='email-input-container'>
                        <div className='email-input-field'>
                            <Field
                                type='email'
                                id='email'
                                name='email'
                                className={classNames('loyalty-email-input', { 'has-error': errors.email })}
                                disabled={isSubmitting}
                                placeholder={t('input.email.placeholder')}
                            />
                            <span className='envelope'>
                                <MdOutlineMail />
                            </span>
                        </div>
                    </div>
                    <ErrorMessage
                        name='email'
                        component='p'
                        className='u-error'
                    />

                    <div className='loyaltyregistration-cta'>
                        <Button
                            disabled={isSubmitting}
                            type='submit'
                            label='Next'
                            variant='dark'
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default LoyaltyRegistrationForm
