import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { Field, Form, Formik } from 'formik'
import { motion } from 'framer-motion'
import * as Yup from 'yup'

import { staggerFadeInUpChild, staggerFadeInUpContainer } from '../../constants/Animations.ts'
import { openModal, updateTokens } from '../../redux/actions/actionBuilders'
import BillySDK from '../../sdk/sdk'
import ModalType from '../../structures/Enums/ModalType.ts'

import Checkbox from './elements/Checkbox'
import CodeInput from './payment/code/CodeInput'

function PromoValidationForm({ color, onSuccessfulVerify = () => undefined }) {
    const { t } = useTranslation()
    const [isValidCode, setIsValidCode] = useState(false)
    const dispatch = useDispatch()

    const verifyOtp = useMutation({
        retry: 2,
        refetchOnWindowFocus: false,
        mutationFn: (code) => BillySDK.promotionsOtpVerify(code),
    })

    const onError = (error, formActions) => {
        dispatch(
            openModal({
                type: ModalType.ERROR_MODAL,
                data: {
                    message: error?.message,
                },
            })
        )
        formActions.setSubmitting(false)
    }

    const onHandleSubmit = useCallback((values, formActions) => {
        verifyOtp.mutate(
            { code: values.code },
            {
                onSuccess: (response) => {
                    if (response.token) {
                        dispatch(
                            updateTokens({
                                access_token: response.token,
                            })
                        )
                        onSuccessfulVerify()
                    } else {
                        onError(undefined, formActions)
                    }
                },
                onError: (error) => onError(error, formActions),
            }
        )
    }, [])
    const onIsValidCode = useCallback((isValid) => {
        setIsValidCode(isValid)
    }, [])

    return (
        <Formik
            onSubmit={(values, actions) => onHandleSubmit(values, actions)}
            initialValues={{
                code: '',
                conditions: false,
                marketing: false,
            }}
            validationSchema={Yup.object().shape({
                code: Yup.string().required(t('validation.phone.required')),
                conditions: Yup.bool().oneOf([true], t('validation.email.required')),
                marketing: Yup.bool().oneOf([true], t('validation.email.required')),
            })}>
            {({ values, status, isSubmitting, handleChange, handleBlur }) => (
                <Form>
                    <motion.div
                        variants={staggerFadeInUpContainer}
                        initial='hidden'
                        animate='show'>
                        <motion.div variants={staggerFadeInUpChild}>
                            <Field
                                fields='4'
                                key='code'
                                component={CodeInput}
                                name='code'
                                id='code'
                                label={t('promotions.input.otp_code.label', 'Your personal activation code:')}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                                onIsValidCode={onIsValidCode}
                                autoComplete='one-time-code'
                            />
                        </motion.div>

                        <motion.div variants={staggerFadeInUpChild}>
                            <Field
                                component={Checkbox}
                                id='conditions'
                                name='conditions'
                                type='checkbox'
                                label={t(
                                    'promotions.legal.abi.conditions_checkbox.label',
                                    'I accept the <a target="_blank" href="/legal/tada_privacy">Terms & Conditions</a> and <a target="_blank" href="/legal/tada_privacy">privacy policy</a> to receive my promo.'
                                )}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                            />
                        </motion.div>

                        <motion.div variants={staggerFadeInUpChild}>
                            <Field
                                component={Checkbox}
                                id='marketing'
                                name='marketing'
                                type='checkbox'
                                label={t(
                                    'promotions.legal.abi.marketing_checkbox.label',
                                    'I agree to receive personalized marketing emails, SMS and Social Media communications with the latest offers and information from AB InBev Brands in exchange for this promotion.'
                                )}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                onBlur={handleBlur}
                            />
                        </motion.div>

                        <div style={{ marginTop: '10px' }}>
                            {status && status.loginstatus && (
                                <div>
                                    <p className='u-error u-text-center'>{status.loginstatus}</p>
                                </div>
                            )}

                            <motion.div
                                style={{ position: 'relative' }}
                                variants={staggerFadeInUpChild}>
                                <input
                                    style={color && { background: color }}
                                    className='c-button c-button-dark'
                                    type='submit'
                                    disabled={
                                        isSubmitting ||
                                        !(
                                            isValidCode === true &&
                                            values.conditions === true &&
                                            values.marketing === true
                                        )
                                    }
                                    value={
                                        isSubmitting
                                            ? t('button.form.disabled.cta')
                                            : t('promotions.add_to_basket.cta', 'Add to basket')
                                    }
                                />
                            </motion.div>
                        </div>
                    </motion.div>
                </Form>
            )}
        </Formik>
    )
}

export default PromoValidationForm
