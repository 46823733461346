import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import Modal from 'src/components/01_atoms/Modal/Modal'
import ModalControls from 'src/components/01_atoms/ModalControls/ModalControls'
import Button from 'src/components/Button/Button'
import CrossSellProductItem from 'src/components/CrossSell/CrossSellProductItem'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'

import './crosssellmodal.scss'

const CrossSellModal: FC<IModalDataProperties> = ({ data }) => {
    const { t } = useTranslation()

    const handleClose = useCallback(() => {
        data.onContinueOrder?.()
    }, [data])

    return (
        <Modal
            containerClassName='crosssellmodal-container'
            contentClassName='crosssellmodal-content'>
            <div className='inner'>
                <div className='crosssellmodal-close'>
                    <button
                        aria-label={t('general.button.close.label')}
                        onClick={handleClose}
                        className='c-button-close-modal-small'>
                        <FaTimes />
                    </button>
                </div>

                <div className='crosssellmodal-title'>
                    <h2 className='crosssellmodal-title-name'>{data.title}</h2>
                </div>

                <div className='crosssellmodal-items'>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {data.products.map((product: any) => (
                        <CrossSellProductItem
                            key={product.product.id}
                            eventId={data.event_id}
                            product={product.product}
                            type='cross_sell'
                            ruleId={product.rule_id}
                        />
                    ))}
                </div>

                <ModalControls>
                    <Button
                        className='add-button'
                        label={t('upsell.button.deny')}
                        onClick={handleClose}
                        variant='dark'
                    />
                </ModalControls>
            </div>
        </Modal>
    )
}

export default CrossSellModal
