import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaAngleLeft, FaAngleRight, FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import Modal from 'src/components/01_atoms/Modal/Modal'
import ModalControls from 'src/components/01_atoms/ModalControls/ModalControls'
import Addition from 'src/components/02_molecules/Addition/Addition'
import Button from 'src/components/Button/Button'
import ProgressBar from 'src/components/ProgressBar/ProgressBar'
import { IAdditionValue } from 'src/hooks/useValidateAdditions'
import { closeModal } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IAddition from 'src/structures/Interfaces/IAddition'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'
import IProduct from 'src/structures/Interfaces/IProduct'

import './productconfiguratormodal.scss'

const ProductConfiguratorModal: FC<IModalDataProperties> = ({ data }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const product: IProduct = data?.product ?? null
    const additions: Array<IAddition> = data.additions ?? null

    const steps = data.additions.length

    const [step, setStep] = useState<number>(data.currentIndex)
    const isLastStep = step === steps - 1

    const [mergedAdditionsValues, setMergedAdditionsValues] = useState<Record<string, IAdditionValue>>({})

    const handleValueChange = useCallback((values: IAdditionValue) => {
        setMergedAdditionsValues((previousState) => ({ ...previousState, [values.id.toString()]: values }))
    }, [])

    useEffect(() => {
        if (Object.keys(mergedAdditionsValues).length > 0) {
            const additionsArray = Object.values(mergedAdditionsValues)
            const allAdditionGroupsValid = additionsArray.every((v: IAdditionValue) => v.isValid)
            data.onAdditionsChange({ isValid: allAdditionGroupsValid, values: additionsArray })
        }
    }, [mergedAdditionsValues, data?.onAdditionsChange])

    const handleNext = useCallback(() => {
        setStep((previousStep) => previousStep + 1)
    }, [step])

    const handleBack = useCallback(() => {
        setStep((previousStep) => previousStep - 1)
    }, [step])

    const handleDone = useCallback(() => dispatch(closeModal()), [])

    if (isNotNullOrUndefined(product)) {
        return (
            <Modal
                containerClassName='productconfiguratormodal-container'
                contentClassName='productconfiguratormodal-content'>
                <div className='inner'>
                    <div className='productconfiguratormodal-close'>
                        <button
                            aria-label={t('general.button.close.label')}
                            onClick={handleDone}
                            className='c-button-close-modal-small'>
                            <FaTimes />
                        </button>
                    </div>

                    <div className='productconfiguratormodal-title'>
                        <h2 className='productconfiguratormodal-title-product-name'>{product.name}</h2>
                    </div>

                    {additions.map((addition) => (
                        <div
                            className='productconfiguratormodal-additions'
                            key={`${addition.id}-${step}`}
                            style={{ display: addition.id === data.additions[step].id ? 'block' : 'none' }}>
                            <Addition
                                isOrderable
                                initialValues={
                                    mergedAdditionsValues[addition.id.toString()]?.values ??
                                    data.currentValues?.values?.find(
                                        ({ id }: { id: number }) => id.toString() === addition.id.toString()
                                    )?.values ??
                                    null
                                }
                                addition={addition}
                                onValues={handleValueChange}
                            />
                        </div>
                    ))}

                    {data.canNavigateSteps === false ? (
                        <ModalControls>
                            <div className='productconfiguratormodal-controls'>
                                <div className='buttons'>
                                    <Button
                                        type='button'
                                        variant='dark'
                                        onClick={handleDone}
                                        label={t('button.confirm.cta')}
                                        disabled={
                                            mergedAdditionsValues?.[data.additions?.[step]?.id]?.isValid === false
                                        }
                                    />
                                </div>
                            </div>
                        </ModalControls>
                    ) : (
                        <ModalControls>
                            <div className='productconfiguratormodal-controls'>
                                <div className='steps-header'>
                                    <div className='steps-label'>
                                        <p>
                                            <span>{`${step + 1}`}</span>
                                            {`/${steps}`}
                                        </p>
                                    </div>
                                    <div className='steps-progress'>
                                        <ProgressBar progress={Math.round(((step + 1) / steps) * 100)} />
                                    </div>
                                </div>
                                <div className='buttons'>
                                    {step > 0 ? (
                                        <>
                                            <Button
                                                type='button'
                                                variant='ghost'
                                                colorVariant='dark'
                                                label={t('button.previous')}
                                                onClick={handleBack}
                                                leftChild={<FaAngleLeft />}
                                            />
                                            <div style={{ width: '20px' }} />
                                        </>
                                    ) : (
                                        <div />
                                    )}
                                    {!isLastStep ? (
                                        <Button
                                            type='button'
                                            variant='dark'
                                            onClick={handleNext}
                                            label={t('button.next')}
                                            rightChild={<FaAngleRight />}
                                            disabled={
                                                mergedAdditionsValues?.[data.additions?.[step]?.id]?.isValid === false
                                            }
                                        />
                                    ) : null}

                                    {isLastStep ? (
                                        <Button
                                            type='button'
                                            variant='dark'
                                            onClick={handleDone}
                                            label={t('button.confirm.cta')}
                                            disabled={
                                                mergedAdditionsValues?.[data.additions?.[step]?.id]?.isValid === false
                                            }
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </ModalControls>
                    )}
                </div>
            </Modal>
        )
    }

    return null
}

export default ProductConfiguratorModal
