enum RoutePath {
    SALES_REDIRECT = '/',
    HOMEPAGE = '/start',
    GENT_SMAAKT = '/gentsmaakt',

    PLACE = '/places/:placeId',
    VENUE = '/venues/:venueId',
    EVENT = '/events/:eventId',
    EVENTS = '/events',
    EVENT_GROUP = '/eventgroups/:eventGroupId',
    EVENT_GROUP_EVENT = '/eventgroups/:eventGroupId/:eventId',
    SELF_SCAN = '/selfscan/:eventId',

    LOGIN = '/login',
    PASSWORD_RESET_REQUEST = '/passwordresetrequest',
    PASSWORD_RESET = '/passwordreset/:token',
    REGISTER = '/register',
    AUTH_CALLBACKS = '/callbacks/:provider',

    HOWTO = '/howto',
    HELP = '/help',
    LEGAL_TERMS = '/legal/terms',
    LEGAL_PRIVACY = '/legal/privacy',
    LEGAL_TADA_PRIVACY = '/legal/tada_privacy',
    LEGAL_DISCLAIMER = '/legal/disclaimer',

    ORDER = '/orders/:orderId',
    RECEIPT = '/orders/:orderId/receipt',
    ORDERS_OVERVIEW = '/orders',

    ORDER_GROUP = '/ordergroups/:ordergroupId',

    PROFILE = '/profile',
    PROFILE_EDIT = '/edit-profile',

    CREDITS = '/credits',
    CREDITS_CLAIM = '/credits/claim',
    CREDIT_PURCHASES_OVERVIEW = '/venues/:venueId/creditpurchases',
    CREDIT_PURCHASES_DETAIL = '/creditpurchases/:purchaseId',

    VOUCHER_PURCHASES_DETAIL = '/voucherpurchases/:purchaseId',
    VOUCHER_TOPUP_OVERVIEW = '/vouchertopups',
    VOUCHER_TOPUP_DETAIL = '/vouchertopups/:purchaseId',

    STARNET_TOPUP_OVERVIEW = '/starnettopups',
    STARNET_TOPUP_DETAIL = '/starnettopups/:purchaseId',

    COMPETITIONS = '/competitions/:competitionClaimUuid',

    LOYALTY_OVERVIEW = '/loyalty',

    NOT_FOUND = '*',
}

export default RoutePath
